.auth-bg {
  background-color: var(--primary-color);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-wrapper {
  max-width: 360px;
  color: var(--text-max);
  .logo {
      width: 125px;
  }
  .auth-action {
    color: var(--primary-color);
  }
}

.verify-loader {
  width: 30px;
  height: 30px;
}