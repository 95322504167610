.head {
    .meta {
        border: 1px solid var(--border-color);
        background-color: var(--hover-color);
    }
}

.info {
    .gradient-bg {
        background-color: var(--primary-color);
        filter: blur(75px);
        width: 50%;
        height: 125px;
        position: absolute;
        top: 0px;
        right: 0;
        z-index: -1;
        opacity: 50%;
    }
}

.link-card {
    color: var(--text-invert);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-decoration: none;
    display: block;
    transition: .3s ease-in-out;
    .subtitle {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.5em;
        min-height: 4.5em;
    }
    .icon {
        width: 36px; 
        height:36px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}

.shift-info {
    border: 1px solid var(--primary-color);
}